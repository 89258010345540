<div class="outerServiceSupport">
    <p class="heading">PRODUCT REGISTRATION </p>
    <p class="headingMsg">Register your product to avail product warranty. Extended warranty offer on selected products.</p>
    <p class="errorLabel" #errorLabel></p>
    <form novalidate [formGroup]="productRegistrationForm" (ngSubmit)="submitProductRegistration(errorLabel)">
<div class="row">
    <div class="fieldWrapper onecolumnrow" >
        <div class="label-container label seperator">
                Customer Name*:
        </div>
        <div class="field valueField" style="width: 95%;">
                <input type="text" class="textFields" maxlength="100" formControlName="customerName" (keypress)="keyPressOnlyChars($event)">
        </div>
    </div>

</div>
<div class="row">
        <div class="fieldWrapper">
            <div class="label-container label seperator">
                  Email*:
            </div>
            <div class="field valueField">
                    <input type="email" class="textFields" maxlength="255" formControlName="email">
            </div>
        </div>
         <div class="fieldWrapper">
             <div class="label-container label seperator">
                   Mobile Number*:
             </div>
             <div class="field  valueField">
                    <input type="text" class="textFields" formControlName="mobileNo" maxlength="10" minlength="10"
                    pattern="[0-9]*" (keypress)="keyPressOnlyNumbers($event)">
             </div>
         </div>
    </div>
    <div class="row">
            <div class="fieldWrapper">
                <div class="label-container label seperator">
                    Product Name*
                </div>
                <div class="field valueField">
                        <input type="text" class="textFields" maxlength="100" formControlName="productName">
                </div>
            </div>
             <div class="fieldWrapper">
                 <div class="label-container label seperator">
                      Date of Purchase*:
                 </div>
                 <div class="field  valueField">
                        <input type="date" class="textFields" [max]="maxDate" maxlength="10" formControlName="dateOfPurchase">
                 </div>
             </div>
        </div>
        <div class="row">
                <div class="fieldWrapper onecolumnrow" >
                    <div class="label-container label seperator">
                            Address*:
                    </div>
                    <div class="field valueField" style="width: 95%;">
                            <input type="text" class="textFields" maxlength="500" formControlName="address">
                    </div>
                </div>
            
            </div>
           
                <div class="row">
                        <div class="fieldWrapper">
                            <div class="label-container label seperator">
                               City*:
                            </div>
                            <div class="field valueField">
                                    <input type="text" class="textFields" maxlength="60" formControlName="city">
                            </div>
                        </div>
                         <div class="fieldWrapper">
                             <div class="label-container label seperator">
                               Pin Code*:
                             </div>
                             <div class="field  valueField">
                                    <input type="text"  class="textFields" maxlength="6"  minlength="6" formControlName="pincode" (keypress)="keyPressOnlyNumbers($event)">
                             </div>
                         </div>
                    </div>
                    <div class="row">
                            <div class="fieldWrapper">
                                <div class="label-container label seperator"> Sr. No:
                                </div>
                                <div class="field valueField">
                                        <input type="text" class="textFields" maxlength="60" formControlName="itemNo">
                                </div>
                            </div>
                             <div class="fieldWrapper">
                                 <div class="label-container label seperator">
                                   Purchased From*:
                                 </div>
                                 <div class="field  valueField">
                                    <select class="textFields" formControlName="purchasedFrom" (change)="onPurchaseFromSelection($event.target.value)">
                                        <option *ngFor="let item of purchaseOptions" [value]="item.value">{{item.label}}</option>                  
                                      </select>
                                 </div>
                             </div>
                        </div>
                        <div class="row">
                                <div class="fieldWrapper">
                                        <div class="label-container label seperator" >
                                             You will find it On the Rating label on the Product 
                                        </div>
                                        <div class="field">
                                            
                                         </div>
                                </div>
                                 <div class="fieldWrapper">
                                        <div class="label-container label seperator"  >
                                                Upload Invoice/Proof of purchase for warranty Registration*:
                                        </div>
                                        <div class="field upload-file" >
                                                <input type="file" class="file-upload" maxlength="60"  formControlName="invoiceFile" (change)="onFileChange($event,1)">
                                        </div>
                                        <div class="label-container label seperator">
                                            (Allowed files types: pdf,jpg,jpeg )
                                        </div>
                                 </div>
                            </div>
                            <div class="row">
                                <div class="fieldWrapper" *ngIf="isUploadReviewEnable">
                                        <div class="label-container label seperator"  >
                                                Upload the review screenshot*: 
                                        </div>
                                        <div class="field upload-file" >
                                                <input type="file" class="file-upload"maxlength="60"  formControlName="reviewScreenshotFile" (change)="onFileChange($event,2)">
                                            </div>
                                            <div class=" label-container label seperator" >
                                                (Allowed files types:jpg,jpeg,png )
                                            </div>
                                </div>
                                 
                            </div>
                            
      
       

        


        <button class="button" [disabled]="!productRegistrationForm.valid">Submit</button>
    </form>
</div>